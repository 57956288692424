import { Carro } from './../models/carro.model';
import { listaCarros } from './../models/listaCarros';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { isNull } from 'util';
import { GlobalService } from "../services/global.service";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  products: Carro;
  listCarr: listaCarros[];

  constructor(private apiService: ApiService,
            public global: GlobalService) {

   }

    async cart(): Promise<Carro> {
        this.products = await this.apiService.getCarrito('/api/cart');
        console.log('Productos en carro: ' + this.products);
        return this.products;
    }

    
    async listaCarritos(): Promise<listaCarros[]> {
        this.listCarr =  await this.apiService.getListaCarrito('/api/cart/list');        
        this.getDescripcion();
        return this.listCarr;
    }

    async getDescripcion()
    {
        const car = this.listCarr.find(c => c.id.toString() == this.global.idCarritoActivo);
        if (car) 
            this.global.descripCarritoActivo = car.descripcion;
        else 
        {
            this.global.idCarritoActivo = '1';
            await this.getDescripcion();
        }
    }
    

    /*async addcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }

    async delcart(codigo): Promise<Product[]> {
        this.products = await this.apiService.getArticulos('/api/articulo/' + codigo);
        console.log('Producto: ' + this.products);
        return this.products;
    }*/
}
