import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController, LoadingController, IonInfiniteScroll, ToastController } from '@ionic/angular';
import { Product } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { CartComponent } from '../cart/cart.component';
import { cantidadesService } from "../../services/cantidades.service";

import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../api.service';
import { PopoverController } from '@ionic/angular';
import { ShareComponent } from './../../components/share/share.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Carro } from './../../models/carro.model';
import { CartService } from './../../services/cart.service';
import { FilterComponent } from '../filter/filter.component';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    animations: [
        trigger('dropdownAnimation', [
          state('open', style({
            height: '*',
            opacity: 1,
            visibility: 'visible'
          })),
          state('closed', style({
            height: '0px',
            opacity: 0,
            visibility: 'hidden'
          })),
          transition('open <=> closed', [
            animate('0.3s ease-in-out')
          ])
        ])
      ]
})
export class SearchComponent implements OnInit {

    // List of Products  
    products: Product[];

//    isDropdownOpen: boolean = false;

    windowWidth: number;

    nophoto = '';

    grid: number = 1;
    //search: string;

    constructor(    private cartService: CartService, 
                    public modalController: ModalController,
                    private productsService: ProductsService, 
                    public global: GlobalService,
                    public ctdadService: cantidadesService,
                    private router: Router, 
                    public loadingController: LoadingController, 
                    public apiService: ApiService,
                    public popoverController: PopoverController, 
                    public toastController: ToastController) 
                    { 
                        this.windowWidth = window.innerWidth;
                        console.log(this.windowWidth);
                        console.log("this.windowWidth",this.windowWidth);
                    }

                    
                      @HostListener('window:resize', ['$event'])
                      onResize(event) {
                        this.windowWidth = event.target.innerWidth;
                        console.log(this.windowWidth);
                        console.log("event.target",event.target);
                      }


    ngOnInit() {

        this.products = this.global.products;
        this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';

        if ((this.products === undefined) || (this.products == null)) {
            this.presentLoading();
            this.getProductList();
        }
    }



    onError(item) {
        console.log(item.imagen);
        item.imagen = this.nophoto;
    }

    ionViewDidEnter() {
    }

    // loading de datos
    presentLoading() {
        this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Cargando productos...'
        }).then((res) => {
            res.present();

            res.onDidDismiss().then((dis) => {
                
            });
        });

        setTimeout(() => {
            this.loadingController.dismiss().then((res) => {
                
            }).catch((error) => {
                
            });
        }, 3000);
    }

  

    // Get List of Products
    getProductList() {
        console.log("this.getProductList");
        this.productsService.productList().then(products => {
            this.products = products;
            this.global.products = products;
            (this.products);

            // quitamos la precarga
            this.loadingController.dismiss().then((res) => {
                console.log('Loading dismissed!', res);
            }).catch((error) => {
                console.log('error', error);
            });

        });
    }

    // Go to cart page function
    async gotoCartPage() {
        this.dismiss();
        const modal = await this.modalController.create({
            component: CartComponent
        });
        modal.onDidDismiss().then((data) => {
            this.getProductList();

        })
        return await modal.present();
    }

    // Back to previous page function
    dismiss() {
        this.modalController.dismiss({
            dismissed: true
        });
    }

    showOneColumn() {
        this.grid = 2;
    }

    // En Grid
    showGrid() {
        this.grid = 1;
    }

    // En lista
    showList() {
        this.grid = 3;
    }

}
