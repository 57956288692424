import { Injectable } from '@angular/core';
import { Category } from '../models/category.model';
import { ApiService } from '../api.service';
import { Storage } from  '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categories: Category[];
  products: Product[];

  //public Host : String = "http://localhost:8000/api";
  constructor(private apiService: ApiService, private http: HttpClient, private  storage:  Storage) { }

  async categoryList(): Promise<Category[]> {
    if (this.categories == null || this.categories.length === 0) {
      this.categories = await this.apiService.getCategorias('/api/categorias');
      console.log("CATEGORIAS : ",this.categories);
    }
    return this.categories;
  }

  /*async catalogoList(): Promise<Category[]> {
    if (this.categories == null || this.categories.length === 0) {
      this.categories = await this.apiService.getCategorias('/api/catalogos');
      console.log(this.categories);
    }
    return this.categories;
  }*/

  async catalogo(codigo): Promise<Product[]> {
      this.products = await this.apiService.getCategoriasproducts('/api/catalogo/' + codigo);
      return this.products;
  }

}
