import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroArticulo'
})
export class FiltroArticuloPipe implements PipeTransform {

    transform(items: any[], _cadena: string, _exactaPorCodigo : boolean): any[] 
    {
        console.log( _cadena, _exactaPorCodigo );
        if (!items || !_cadena) 
        {
          return items;
        }

        if( _exactaPorCodigo )
            return items.filter(item => item.codigo.toLowerCase()== _cadena.toLowerCase());
        else
            return items.filter(item => ( item.codigo.toLowerCase().includes(_cadena.toLowerCase()) || item.descripcion.toLowerCase().includes(_cadena.toLowerCase()) ) );
                
      }

}
