import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductDetailsComponent } from '../../pages/product-details/product-details.component';
import { GlobalService } from '../../services/global.service';
import { PopoverController } from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ShareComponent } from './../../components/share/share.component';
import { ApiService } from '../../api.service';
import { Carro } from './../../models/carro.model';
import { CartService } from './../../services/cart.service';

@Component({
    selector: 'app-featured-products',
    templateUrl: './featured-products.component.html',
    styleUrls: ['./featured-products.component.scss'],
})
export class FeaturedProductsComponent implements OnInit
{
    public static instance: FeaturedProductsComponent;
    products: Product[];
    cartProducts: Carro;
    public items: Product[];
    resultado = '';
    nophoto = '';

    sizeXl = "2";
    sizeLg = "3";
    sizeMd = "4";
    sizeXs = "6";
    sizeColHist = "12";
    sizeColHistBotones = "9";


    count: number = 0;
    control: Boolean = false;
    grid: Boolean = true;
    oneColumn: Boolean = false;
    list: Boolean = false;


    // Slider Options
    /*slideOpts = {
      initialSlide: 0,
      speed: 500,
      delay:50,
      pagination:true,
      autoplay:true,
      slidesPerView: 1,
    };*/

    slideOpts = {
        initialSlide: 0,
        speed: 500,
        delay: 50,
        pager: true,
        pagination: true,
        autoplay: true,
        slidesPerView: 1
    };

    constructor(private cartService: CartService, public toastController: ToastController, private router: Router, public popoverController: PopoverController, private productsService: ProductsService,
        private modalController: ModalController, public global: GlobalService, public apiService: ApiService) { }

    //cargamos los 100 datos iniciales
    /*cargadatos(){
      //cargamos los primeros 5
      for (let i = 0; i < 5; i++) {  // here you can limit the items according to your needs.
        this.global.productsPref.push(this.global.productsPref[this.count]);   // your JSON data which you want to display
        this.count++ //i am using a count variable to keep track of inserted records to avoid inserting duplicate records on infinite scroll
      }
    }*/

    ngOnInit()
    {

        console.log("entro");
        //this.items = [];
        //this.products = this.global.productsPref;
        this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';
        this.getProductList();
    }

    ionViewWillEnter()
    {
        console.log(this.global.productsPref);
    }



    async settingsPopover(item1)
    {
        const siteInfo = { id: 1, name: 'edupala' };
        const popover = await this.popoverController.create({
            component: ShareComponent,
            cssClass: 'popover_setting',
            componentProps: {
                site: siteInfo,
                producto: item1
            },
            translucent: true
        });

        popover.onDidDismiss().then((result) =>
        {
            console.log(item1);
            /*this.global.itemChat = item1;
            this.router.navigate(['/tabs/tab2']);*/
        });

        return await popover.present();
        /** Sync event from popover component */

    }

    onError(item)
    {
        console.log(item.imagen);
        item.imagen = this.nophoto;
    }

    changeQty(item, i, change: number)
    {

        let qty = 0;
        let control = 1;

        setTimeout(() =>
        {
            if (this.global.unidadMedidaMiniaturas329 == 0)
            {
                qty = item.unid2;
                control = 2;
            } else if (this.global.unidadMedidaMiniaturas329 == 1)
            {
                qty = item.unid1;
            } else if (this.global.unidadMedidaMiniaturas329 == 2)
            {
                if (item.precioSobre == 'S')
                {
                    qty = item.unid1;
                } else
                {
                    qty = item.unid2;
                    control = 2;
                }
            }
            if (change < 0 && item.unid1 == 0 && control == 1)
            {
                return;
            }
            if (change < 0 && item.unid2 == 0 && control == 2)
            {
                return;
            }

            qty = Number(qty) + Number(change);

            console.log(qty);

            if (control === 1)
            {
                item.unid1 = qty;
            } else
            {
                item.unid2 = qty;
            }
            console.log(item.unid2);
        }, 5);
    }

    /*
    async addtocart(articulo, i)
    {
        console.log("featyr");
        console.log(articulo);
        console.log(i);
        articulo = await this.apiService.dameUnidades(articulo);
        this.resultado = await this.apiService.cartAdd(articulo);
        console.log(this.resultado);
        this.getCartItems(articulo.nombre);

    }
    */

    async getCartItems(nombre)
    {
        this.cartService.cart().then((products) =>
        {
            this.cartProducts = products;
            const object = this.cartProducts.articulos;
            console.log('Articulos carro: ' + object);
            this.global.artcart = Object.keys(object).length;
        });
        const toast = await this.toastController.create({
            message: nombre + ' añadido a tu carrito.',
            duration: 2000
        });
        toast.present();
    }

    // Añadir a favoritos
    async FavAdd(articulo)
    {
        console.log(articulo);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.global.productsPref.length; i++)
        {
            if (this.global.productsPref[i].codigo === articulo)
            {
                (this.products[i]);
                this.resultado = await this.apiService.FavAdd(this.global.productsPref[i].codigo);
                (this.resultado);
                this.global.productsPref[i].isWishlist = true;
                this.global.cambio = true;
            }
        }

    }

    // Quitar de favoritos
    async FavDel(articulo)
    {
        console.log(articulo);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.global.productsPref.length; i++)
        {
            if (this.global.productsPref[i].codigo === articulo)
            {
                console.log(this.global.productsPref[i]);
                this.resultado = await this.apiService.FavDel(this.global.productsPref[i].codigo);
                console.log(this.resultado);
                this.global.productsPref[i].isWishlist = false;
                this.global.cambio = true;
            }
        }

    }

    getProductList()
    {
        this.productsService.productPref().then(products =>
        {
            this.global.productsPref = products;

            // recorremos el array del carro para poner cantidades
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < Object.keys(this.global.cartProducts.articulos).length; i++)
            {

                let found = this.global.productsPref.find(x => x.codigo === this.global.cartProducts.articulos[i].codigo);
                if (found != null)
                {
                    found.cesta = true;
                    if( !this.global.carritoSumaUnidadesL06 )
                    {
                        found.unid1 = this.global.cartProducts.articulos[i].unid1;
                        found.unid2 = this.global.cartProducts.articulos[i].unid2;
                    }
                }

                found = null;
            }
        });
    }
    // Go to product details page
    async goToProductDetails(item1)
    {
        const modal = await this.modalController.create({
            component: ProductDetailsComponent,
            componentProps: { producto: item1 }
        });
        return await modal.present();
    }
}
