import { Ean } from './ean.model';
import { Promocion } from './promocion.model';
import { Tarifa } from './tarifa.model';
import { familia } from './familia.model';
import { caracteristicas } from './caracteristicas.model';

export class Product {
    codigo: string;
    nombre: string;
    descripcion: string;
    precioVenta: number;
    descuentoPorcVenta: number;
    descuentoImpoVenta: number;
    imagen: string;
    IVA: string;
    ivainc: string;
    tipoIva: string;
    unid1: number;
    unid2: number;
    modificado : boolean;
    kilos : number;
    depeso : string;
    unidadvta : string;
    peso : number;
    promocionAplicada: boolean;
    puntoVerde: number;
    formaRedondeo: number;
    relacionUnidades: number;
    precioSobre: string;
    medi1: string;
    medi2: string;
    isWishlist: boolean;
    cesta: boolean;
    tarifa: Tarifa;
    promocion: Promocion;
    familia: familia;
    caracteristicas: caracteristicas;
    eans: Ean[];
    datosHistorico:number;
    

    constructor(public Promocion: Promocion, public Tarifa: Tarifa, public Familia: familia, public Caracteristicas: caracteristicas) {
        this.codigo = '';
        this.nombre = '';
        this.descripcion = '';
        this.precioVenta = 0;
        this.descuentoPorcVenta = 0;
        this.descuentoImpoVenta = 0;
        this.imagen = '';
        this.IVA = '';
        this.ivainc = '';
        this.tipoIva = '';
        this.unid1 = 0;
        this.unid2 = 0;
        this.promocionAplicada = false;
        this.puntoVerde = 0;
        this.formaRedondeo = 0;
        this.relacionUnidades = 0;
        this.precioSobre = '';
        this.medi1 = '';
        this.medi2 = '';
        this.isWishlist = false;
        this.tarifa = Tarifa;
        this.promocion = Promocion;
        this.familia = Familia;
        this.caracteristicas = Caracteristicas;
        this.cesta = false;
        this.eans = [];
    }

    public toString = (): string => {
        return JSON.stringify(this) ;
    }
}
