import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';

import { Product } from "../../models/product.model";
import { ProductsService } from "../../services/products.service";
import { cantidadesService } from "../../services/cantidades.service";
import {
    PopoverController,
    ModalController,
    LoadingController,
    IonInfiniteScroll,
    ToastController,
    NavController
} from "@ionic/angular";
import { GlobalService } from "../../services/global.service";
import { CartService } from "../../services/cart.service";
import { ApiService } from "../../api.service";
import { Storage } from "@ionic/storage";
import { Router, ActivatedRoute } from "@angular/router";
import { Carro } from "../../models/carro.model";
import { CategoryService } from "../../services/category.service";

import { ProductDetailsComponent } from "../../pages/product-details/product-details.component"
import { NgModule } from '@angular/core';


/*@NgModule({
    imports: [
      FiltroArticuloPipe
    ],
  })*/

@Component({
    selector: 'app-show-articulo-grid',
    templateUrl: './show-articulo-grid.component.html',
    styleUrls: ['./show-articulo-grid.component.scss'],
})
export class ShowArticuloGridComponent implements OnInit {

    @ViewChild(IonInfiniteScroll, { static: false })
    infiniteScroll: IonInfiniteScroll;

    @Output() favEvent: EventEmitter<any> = new EventEmitter();    

    @Input() products: Product[];
    @Input() grid: number;    
    //@Input() searchTerm2: string = "";
    @Input() historico : boolean = false;
    @Input() search : boolean = false;

    private oneColumn : boolean = false;
    private sizeXl = "2";
    private sizeLg = "3";
    private sizeMd = "4";
    private sizeXs = "6";
    private sizeColHist = "12";
    private sizeColHistBotones = "9";

    private itemsFound = 1;



    private cartProducts: Carro;

    private incremento = 20;

    constructor(
        private navController: NavController,
        private activeRoute: ActivatedRoute,
        private cartService: CartService,
        private route: ActivatedRoute,
        private router: Router,
        public popoverController: PopoverController,
        private productsService: ProductsService,
        public storage: Storage,
        public ctdadService: cantidadesService,
        public modalController: ModalController,
        public global: GlobalService,
        public loadingController: LoadingController,
        public apiService: ApiService,
        public toastController: ToastController,
        private categoryService: CategoryService

    ) {
    }

    ngOnInit() {

    }

    filtraArticulo(_items: any): any {
        return _items.codigo.toUpperCase().includes(this.global.searchTerm.toUpperCase());
    }


    getFilteredItemCount(): number 
    {
        if( this.global.searchTerm == undefined ) 
            this.itemsFound = 1;
        else
            this.itemsFound = this.products.filter(item => (item.descripcion.toUpperCase().includes(this.global.searchTerm.toUpperCase()))||(item.codigo.toUpperCase().includes(this.global.searchTerm.toUpperCase()))).length;


        return this.itemsFound;
    }


    actualizaFavoritos()
    {
        this.global.cambio = true;
        this.favEvent.emit();
    }

    
    ngOnChanges(changes: SimpleChanges) {
        
        if (changes.grid)       this.changeView();
    }

    
    changeView() {
        
        if(this.search) 
        {
            this.sizeXl = "6";
            this.sizeLg = "6";
            this.sizeMd = "6";
            this.sizeXs = "6";
            this.oneColumn = false;

            this.sizeColHist = "12";
            this.sizeColHistBotones = "9";

        }
        else
        {
            this.sizeXl = ( this.grid == 1 ) ? "2" : "12";
            this.sizeLg = ( this.grid == 1 )  ? "3" : "12";
            this.sizeMd = ( this.grid == 1 )  ? "4" : "12";
            this.sizeXs = ( this.grid == 1 )  ? (window.innerWidth < 350 ? "12" : "6" ): "12";
            this.oneColumn = ( this.grid == 2 ) ;

            this.sizeColHist = ( this.grid == 1 )  ? "12" : "6";
            this.sizeColHistBotones = ( this.grid == 1 )  ? "9" : "6";
        }
    }

    loadData(event) {
        this.incremento = this.incremento + 10;
        event.target.complete();
    }

/*
    async FavAdd(_product, e) {
        console.log("FavAdd GRID");
        e.stopPropagation();

        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].codigo === _product) {
                await this.apiService.FavAdd(
                    this.products[i].codigo
                );
                this.products[i].isWishlist = true;
                this.global.cambio = true;
                this.favEvent.emit();
            }
        }
    }

    async FavDel(_product, e) {
        console.log("FavDel GRID");
        e.stopPropagation();

        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].codigo === _product) {
                await this.apiService.FavDel(
                    this.products[i].codigo
                );
                this.products[i].isWishlist = false;
                this.global.cambio = true;
                this.favEvent.emit();
            }
        }
    }

    async goToProductDetails(_product) {

        const modal = await this.modalController.create({
            component: ProductDetailsComponent,
            componentProps: { producto: _product },
        });
        return await modal.present();
    }

    async addtocart(_product, i) {

        _product = await this.apiService.dameUnidades(_product);
        await this.apiService.cartAdd(_product);
        this.getCartItems(_product.nombre);
    }


    async getCartItems(_productName) {
        this.cartService.cart().then((products) => {
            this.cartProducts = products;
            const object = this.cartProducts.articulos;
            console.log("Articulos carro: " + object);
            this.global.artcart = Object.keys(object).length;
        });
        const toast = await this.toastController.create({
            message: _productName + " añadido a tu carrito.",
            duration: 2000,
        });
        toast.present();
    }


    async compartir(_productName, e) {
        e.stopPropagation();

        this.global.itemChat = _productName;
        this.router.navigate(['/tabs/tab2']);
    }*/
}
