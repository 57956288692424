export class Promocion {
    codigo: string;
    precio: number;
    descuentoI: number;
    descuentoP: number;
    desde: number;
    hasta: number;
    desdeFecha: string;
    hastaFecha: string;
    regalo: string;

    constructor() {
        this.codigo = '';
        this.precio = 0;
        this.descuentoI = 0;
        this.descuentoP = 0;
        this.desde = 0;
        this.hasta = 0;
        this.desdeFecha = '';
        this.hastaFecha = '';
        this.regalo = null;
    }

    public toString = (): string => {
        console.log('puta mierda');
        return JSON.stringify(this) ;
    }
}
