export class Tarifa {
    codigo: string;
    precio: number;
    descuentoI: number;
    descuentoP: number;

    constructor() {
        this.codigo = '';
        this.precio = 0;
        this.descuentoI = 0;
        this.descuentoP = 0;
    }

    public toString = (): string => {
        console.log('puta mierda');
        return JSON.stringify(this) ;
    }
}
