export class caracteristicas {
    carac01: String;
    carac02: String;
    carac03: String;
    carac04: String;
    carac05: String;
    carac06: String;
    carac07: String;
    carac08: String;
    carac09: String;
    carac10: String;
}