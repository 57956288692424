import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class HttpAngularProvider {

    httpHeader = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

    constructor(public http: HttpClient) {}

    public get(url: string, params?: any, options: any = {}) {

        options.params = params;
        options.withCredentials = true;
        const header = {
            headers: new HttpHeaders()
              .set('Authorization',  options.Authorization)
          };

        return this.http.get(url, header);
    }


    public put(url: string, params?: any, options: any = {}) {

        options.params = params;
        options.withCredentials = true;
        //console.log(options.Authorization);

        const header = {
            headers: new HttpHeaders()
              .set('Authorization',  options.Authorization)
          };

        //return this.http.put(url, header);
        return this.http.put(url, null, header).pipe(
        map(res => res));
    }

    public del(url: string, params?: any, options: any = {}) {

        options.params = params;
        options.withCredentials = true;
        //console.log(options.Authorization);

        const header = {
            headers: new HttpHeaders()
              .set('Authorization',  options.Authorization)
          };

          return this.http.delete(url, header);
    }


    public post(url: string, params: any, options: any = {}) {
        options.withCredentials = true;
        //console.log(url);
        //console.log(params);
        const body = params;
        return this.http.post(url, body, options);
    }

    private createSearchParams(params: any) {
        let searchParams = new URLSearchParams();
        for (let k in params) {
            if (params.hasOwnProperty(k)) {
                searchParams.set(k, params[k]);
            }
        }

        return searchParams;
    }
}