import { Carro } from './../../models/carro.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController, IonDatetime, IonContent } from '@ionic/angular';
import { NgZone } from '@angular/core';
import { cantidadesService } from "../../services/cantidades.service";
import { PopoverController } from '@ionic/angular';
import { format } from 'date-fns';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { CartService } from 'src/app/services/cart.service';
import { ApiService } from '../../api.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { GlobalService } from '../../services/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit
{

    @ViewChild("ionDatetime", { static: false }) picker: IonDatetime;
    @ViewChild('content', { static: true }) content: IonContent;

    cartProducts: Carro;
    total = 0;
    resultado = '';
    nophoto = '';
    texto = '';

    private anotacionesCarrito: string;

    private fechaActual = new Date();
    private minDate: string;

    carritoForm: FormGroup;

    //fechaSeleccionada: string;

    constructor(public global: GlobalService,
        private zone: NgZone,
        
        private nativeStorage: NativeStorage,
        public ctdadService: cantidadesService,
        public alertController: AlertController,
        public modalController: ModalController,
        public cartService: CartService,
        private formBuilder: FormBuilder,
        public popoverController: PopoverController,
        public apiService: ApiService)
    {



        let a = 10;

        this.minDate = new Date().toISOString();

        this.carritoForm = this.formBuilder.group({
            fechaReparto: [this.sumarDias(global.diasAvanceFechaReparto519).toString(), [Validators.required, this.fechaMayorQueHoyValidator.bind(this)]]
        }, {
        });



        //if (this.global.fechaReparto == null)
        //this.global.fechaReparto = this.sumarDias(global.diasAvanceFechaReparto519);

        this.carritoForm.updateValueAndValidity();
        this.fechaMayorQueHoyValidator(this.carritoForm);
    }

    ionViewDidEnter()
    {
        console.log("ENTRO", this.anotacionesCarrito);
        this.nativeStorage.getItem('anotacionesCarrito')
            .then(
                data =>
                {
                    this.anotacionesCarrito = data;
                    console.log(this.anotacionesCarrito);
                },
                error => { }
            );

    }

    ionViewDidLeave()
    {

        console.log("SALGO", this.anotacionesCarrito);
        this.nativeStorage.setItem('anotacionesCarrito', this.anotacionesCarrito)
            .then(
                (data) => { },
                (error) => { }
            );
    }

    compararFechas(fecha1: Date, fecha2: Date): number
    {
        // Convertir ambas fechas a objetos Date sin la hora
        const fecha1SinHora = new Date(fecha1.getFullYear(), fecha1.getMonth(), fecha1.getDate());
        const fecha2SinHora = new Date(fecha2.getFullYear(), fecha2.getMonth(), fecha2.getDate());

        // Comparar las fechas
        if (fecha1SinHora < fecha2SinHora)
        {
            return -1;
        } else if (fecha1SinHora > fecha2SinHora)
        {
            return 1;
        } else
        {
            return 0;
        }
    }

    fechaMayorQueHoyValidator(control)
    {
        const fechaSeleccionada = new Date(control.value);
        const hoy = new Date();

        const diaSemana = fechaSeleccionada.getDay();

        if (this.global.solicitoFechaRepartoL03)
        {
            console.log(fechaSeleccionada);
            console.log(hoy);
            //if ((fechaSeleccionada < hoy)) {
            if (this.compararFechas(fechaSeleccionada, hoy) < 0)
            {
                console.log("invalida");
                return { fechaInvalida: true };
            }

            if ((diaSemana === 0 /*|| diaSemana === 6*/))
            {
                return { finde: true };
            }
        }
        return null;
    }

    sumarDias(dias: string): Date
    {
        let fechaModificada = new Date(this.fechaActual);
        fechaModificada.setDate(fechaModificada.getDate() + parseInt(dias));
        return fechaModificada;
    }

    ngOnInit()
    {
        this.getCartItems();
        this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';

        this.content.scrollToPoint(0, 100, 0);
    }

    changeCarrito(_id)
    {
        this.global.idCarritoActivo = _id;
        console.log(this.global.idCarritoActivo);
        console.log(this.global.listaDeCarritos);

        this.cartService.getDescripcion();

        this.getCartItems();
    }

    // Get Cart Items From Storage
    getCartItems()
    {
        this.cartService.cart().then(async (products) =>
        {
            this.cartProducts = products;
            this.global.cartProducts = products;

            const object = this.cartProducts.articulos;
            for (let i = 0; i < object.length; i++)
            {
                object[i] = await this.apiService.dameUnidades(object[i]);
                object[i].modificado = false;

                if ((object[i].depeso == "S") || (object[i].unidadvta == "B"))
                    object[i].kilos = object[i].unid2 * object[i].peso;
            }

            this.global.artcart = Object.keys(object).length;
        });
    }
    dametexto(item)
    {
        this.texto = this.apiService.dametexto(item);
        if (this.texto == 'Unidades')
        {
            return ('Und.');
        } else if (this.texto == 'Cajas')
        {
            return ('Caj.');
        } else
        {
            return ('Und.');
        }
    }

    async inputChange(item, unid, change: number)
    {
        if (unid == 1)
            await this.ctdadService.inputQtySegunUnid1(item, change);
        else
            await this.ctdadService.inputQtySegunUnid2(item, change);

    }


    async actualizaProducto(item)
    {
        this.resultado = await this.apiService.cartAdd(item, true);
        this.getCartItems();
    }


    async changeQty(item, unid, change: number)
    {
        if (unid == 1)
            await this.ctdadService.changeQtySegunUnid1(item, change);
        else
            await this.ctdadService.changeQtySegunUnid2(item, change);
    }

    // Remove Product From Cart
    async removeProduct(product, index)
    {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Quitar artículo',
            message: '¿Desea quitar este artículo del pedido?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) =>
                    {
                        console.log('Cancelado');
                    }
                }, {
                    text: 'Quitar',
                    handler: () =>
                    {
                        this.apiService.cartDel(product.codigo.replace(/ /g, ''));
                        console.log('Confirmado');
                        setTimeout(() =>
                        {
                            this.getCartItems();
                        }, 150);
                    }
                }
            ]
        });

        await alert.present();
    }

    onError(item)
    {
        item.imagen = this.nophoto;
    }
    // Go to checkout page
    goToCheckout()
    {

        console.log(this.carritoForm.value["fechaReparto"]);

        this.alertController.create({
            header: 'Realizar pedido',
            subHeader: 'Confime para continuar',
            message: '¿Está seguro de realizar este pedido ' +
                (this.global.solicitoFechaRepartoL03 ?
                    'Con fecha de reparto ' + format(new Date(this.picker.value), 'dd/MM/yyyy') + ' ?' : '?'),


            buttons: [
                {
                    text: 'Si',
                    handler: () =>
                    {
                        console.log('ha dicho que si, seguimos con el pedido');
                        this.pedido();
                    }
                },
                {
                    text: 'No',
                    handler: () =>
                    {
                        console.log('El pedido queda aún como borrador');
                    }
                }
            ]
        }).then(res =>
        {
            res.present();
        });
    }


    cancelaPedido()
    {


        this.alertController.create({
            header: 'Cancelar pedido',
            subHeader: 'Confime para cancelar',
            message: '¿Está seguro de cancelar este pedido?',

            buttons: [
                {
                    text: 'Si',
                    handler: () =>
                    {
                        this.cancelarPedido();
                    }
                },
                {
                    text: 'No',
                    handler: () =>
                    {
                        ;
                    }
                }
            ]
        }).then(res =>
        {
            res.present();
        });
    }


    async cancelarPedido()
    {
        await this.apiService.cartRemove(
        ).then((resultado) =>
        {
            console.log(resultado);
            if (resultado == '202')
            {
                this.dismiss();
                this.global.artcart = 0;
                this.getCartItems();
                this.presentAlert('Carrito vaciado !!');
            } else
            {
                this.presentAlert('Error eliminando el pedido. Revise la conexión e intentelo de nuevo mas tarde.');
            }
        });
    }

    async pedido()
    {

        await this.apiService.cartClose(
            (this.picker == undefined) ? '' : format(new Date(this.picker.value), 'dd/MM/yyyy'), (this.anotacionesCarrito == undefined) ? '' : this.anotacionesCarrito.replace(/\n/g, " ")
        ).then((resultado) =>
        {

            console.log(resultado);
            if (resultado == '202')
            {
                this.dismiss();
                this.global.artcart = 0;
                this.getCartItems();
                this.presentAlert('Pedido realizado correctamente.');
                this.anotacionesCarrito = "";
                this.nativeStorage.setItem('anotacionesCarrito', "")
                    .then(
                        (data) => { },
                        (error) => { }
                    );
            } else
            {
                this.presentAlert('Error cerrando el pedido. Revise la conexión e intentelo de nuevo mas tarde.');
            }
        });

    }


    // Go to product details page
    async goToProductDetails(item1)
    {
        // console.log(codigoP);
        const modal = await this.modalController.create({
            component: ProductDetailsComponent,
            componentProps: { producto: item1 }
        });
        return await modal.present();
    }

    async presentAlert(err)
    {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: this.global.titulo,
            message: err,
            buttons: ['OK']
        });

        await alert.present();
    }

    // Back to previous page options
    dismiss()
    {
        this.modalController.dismiss({
            dismissed: true
        });
    }

    setDataNascimentoString(event)
    {
        console.log(this.picker);
        this.content.scrollToPoint(0, 100, 0);
        console.log(this.carritoForm);
    }

    async removeCarrito()
    {
        const alert = await this.alertController.create({
            message: 'Desea eliminar el carrito : ' + this.global.descripCarritoActivo + '?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: data =>
                    {
                        console.log('Cancel clicked');
                    }
                },
                {
                    text: 'OK',
                    handler: data =>
                    {

                        this.apiService.removeCarrito( this.global.idCarritoActivo ).then(resolve => 
                            {
                                this.global.idCarritoActivo = '1';
                                this.cartService.listaCarritos().then((listaCarritos) => 
                                    {
                                        this.global.listaDeCarritos = listaCarritos;
                                        this.global.idCarritoActivo = listaCarritos[0].id.toString();
                                        this.changeCarrito(this.global.idCarritoActivo);
                                    } )
                            }
                            ,error => {
                            console.error('Error al eliminar el carrito:', error);
                        });
                        
                    }
                }
            ]
        });
        await alert.present();
    }

    async addCarrito()
    {
        const alert = await this.alertController.create({
            message: 'Descripción del carrito a añadir : ',
            inputs: [
                {
                    name: 'descripcion',
                    placeholder: 'descripcion'
                }
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: data =>
                    {
                        console.log('Cancel clicked');
                    }
                },
                {
                    text: 'OK',
                    handler: data =>
                    {

                        this.apiService.addCarrito( data.descripcion ).then(resolve => {
                            this.global.idCarritoActivo = resolve;
                            this.cartService.listaCarritos().then((listaCarritos) => {
                                this.global.listaDeCarritos = listaCarritos;
                                this.changeCarrito(this.global.idCarritoActivo);
                            });
                        }).catch(error => {
                            console.error('Error al añadir al carrito:', error);
                        });
                        
                    }
                }
            ]
        });
        await alert.present();
    }

}

